// $primary: #4F95FE;
$primary: #1B3357;
$primary-dark: darken($primary, 10%);
$primary-light: lighten($primary, 15%);
$secondary: #F0F0F0;
$search-color: #D9D9D9;
$icon-hover: rgb(206, 206, 206);
$icon-active: rgb(181, 233, 250);

$portfolio-primary: #1B3357;

$mediums: ('music', 'movies', 'books', 'television');

$transition-speed: 300ms;

$breakpoints: (
    'sm' : 640px,
    'med' : 768px,
    'lg' : 1024px,
    'xl' : 1280px,
    '2xl' : 1536px,
);

$ratings: (
    'bad': #cc0000,
    'okay': #F4D03F,
    'good': #229954,
    'amazing': #EC407A,    
);