@import './_variables.scss';
@import './_mixins.scss';

.reviews-container{
    margin-top: 1rem;
    color: black;
    @include center;
    flex-direction: column;
    margin: auto;
    @include mq('med'){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .review-card{
            width: 85%;
            margin-left: auto;
            margin-right: auto;
            height: 100%;
        }
        .review-card-link {
            height: 275px;
            &.vertical{
                height: 325px;
            }
        }
    }
    @include mq('lg'){
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
}

.review-card{
    @include center;
    margin-top: 1rem;
    flex-direction: column;
    padding-bottom: 1rem;
    border-bottom: 1px dashed black;
    width: 85vw;
    &:hover{
        background: lighten($primary, 70%);
    }
}

.review-title{
    font-weight: bold;
    // max-width: 80%;
    text-align: center;
}

.review-date{
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.review-image{
    height: 180px;
    width: 180px;
    &.vertical{
        height: 240px;
    }
}