@import './partials/_components.scss';

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Source Code Pro', monospace;
  font-family: 'Arimo', sans-serif;
}

a {
  text-decoration: none; /* no underline */
  all: unset;
  cursor: pointer;
}

input {
  all: unset;
}

ul{
  margin: 0;
  padding: 0;
}


body{
  color: white;
  &::-webkit-scrollbar-track{
    background: $search-color;
  }
  &::-webkit-scrollbar-thumb{
    background: $portfolio-primary
  }
  &::-webkit-scrollbar{
    width: 0.5rem;
    @include mq('xl'){
      width: 0.75rem;
    }
  }
}

.grid-landing{
  @include grid;
  grid-template-rows: 4em repeat(4, 1fr);
  @include mq('med'){
    grid-template-rows: 4em repeat(2, 1fr);
    grid-template-columns: 1fr 1fr;
  }
}

.grid-primary{
  @include grid;
}

.grid-item{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}