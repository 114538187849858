@import './_variables.scss';
@import './_mixins.scss';
/* GENERAL NAVBAR */
.main-header{
    @include header;
    width: 100%;
    height: 4rem;
    li{
        list-style: none;
    }
    transition: height $transition-speed ease;
}

.active{
    fill: $icon-active;
}

.navbar-nav{
    height: 4rem;
    @include mq('med'){
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.navbar-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq('med'){
        display: block;
    }
}

.navbar-item{
    margin: 1rem 0.5rem;
    display: inline-block;
    &:first-child{
        margin-left: 1rem;
    }
    &:last-child{
        margin-right: 1rem;
    }
    &:hover{
        .navbar-icon{
            transition: all $transition-speed ease;
            fill: $icon-hover;
        }
    }
    @include mq('med'){
        &:first-child{
            display: none;
        }
        margin: auto 2rem;
    }
    @include mq('lg'){
        margin: auto 3.5rem;
    }
}

.navbar-icon{
    color: white;
    height: 2.2rem;
    width: 2.2rem;
}

.navbar-title{
    display: none;
    &:hover{
        transition: all $transition-speed ease;
        color: $icon-hover;
    }
    @include mq('med'){
        color: white;
        display: block;
        margin-left: 1rem;
    }
}
