@import './_variables.scss';
@import './_mixins.scss';

/* LANDING PAGE STYLES */
.landing-header{
    @include center;
    @include header;
    justify-content: space-between;
    @include mq('med'){
        grid-column: span 2;
    }
    @include mq('lg'){
        font-size: 1.5em;
    }
}

.back-btn{
    margin: auto 1rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    &:hover{
        fill: $icon-hover;
        transition: all 0.3s;
    }
    @include mq('lg'){
        width: 2rem;
        height: 2rem;
    }
}

.btn{
    margin: 0;
}

@each $medium in $mediums {
    .#{$medium}{
        background-image: url('../../public/imgs/mediums/#{$medium}.jpg');
        background-blend-mode: multiply;
        background-color: rgba(0, 0, 0, 0.2);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.medium{
    cursor: pointer;
    &:hover{
        background-color: rgba(0, 0, 0, 0.4);
        h2 {
            text-decoration: underline;
            transform: translateY(-5px);
            transition: all 0.5s;
        }
    }
}

.medium h2 {
    font-size: 3em;
    text-shadow: 2px 2px 0px rgba(0,0,0,0.2);
}