@import './_variables.scss';
@import './_mixins.scss';

html {
    scroll-behavior: smooth;
}

.wrapper{
    display: grid;
    grid-template-rows: 675px repeat(5, minmax(min-content, max-content) );
    position: relative;
    @include mq('lg'){
        grid-template-rows: auto repeat(5, minmax(min-content, max-content));
    }
    // min-height: 100vh;
    // min-height: var(--vh);
    // flex-direction: column;
    // @include center;
}

.landing-wrapper{
    display: flex;
    justify-content: center;
    @include mq('lg'){
        min-height: 100vh;
    }
}

.portfolio-container{
    color: $portfolio-primary;
    width: 100%;
    height: 90%;
    margin: auto;
    border-left: 4px solid $portfolio-primary;
    position: relative;
    display: flex;
    flex-direction: column;
    @include mq('lg'){
        height: 80%;
        border: none;
    }
}

.col-white{
    background-color: white;
    z-index: 99;
    width: 15%;
    height: 100%;
    // height: 675px;
    @include mq('lg'){
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: auto;
        width: 15%;
        height: 80%;
        border-right: 4px solid $portfolio-primary;
    }
}

.icon-container{
    display: none;
    @include mq('lg'){
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
        color: $portfolio-primary;
    }
}

.icon{
    animation: 1.5s ease-in 0s 1 fade-in;
    padding: 0.65rem;
    margin-top: 1rem;
    &:hover{
        cursor: pointer;
        color: white;
        background-color: $portfolio-primary;
        background-position: 20px center;
        border-radius: 50%;
    }

}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.name{
    padding-left: 1rem;
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 4.5rem;
    animation: 1s ease-out 0s 1 slide;
    @include mq('lg'){
        padding-left: 3rem;
        font-size: 6rem;
    }
}

.position{
    padding-left: 1rem;
    font-size: 2rem;
    margin-top: 1rem;
    font-weight: 300;
    opacity: 80%;
    animation: 1s ease-out 0s 1 slide-pos;
    @include mq('lg'){
        padding-left: 3rem;
        font-size: 2.5rem;
    }
}

.section-links{
    margin-top: 1rem;
    @include mq('lg'){
        display: flex;
        flex-direction: column;
        min-height: 0;
        // margin-top: 2rem;
    }
}

.link{
    background-color: $portfolio-primary;
    display: block;
    color: white;
    text-align: center;
    width: 80%;
    font-size: 1.4rem;
    margin: 1.2rem 0;
    border-radius: 0 10px 10px 0;
    padding: 0.5rem 0;
    // animation: 1s ease-out 0s 1 slide-sec;
    &:hover{
        background-color: darken($portfolio-primary, 10%);
    }
    @include mq('lg'){
        background-color: white;
        color: $portfolio-primary;
        text-align: left;
        font-size: 1.9rem;
        // margin: 1.7rem 0;
        margin: 1.2vh 0;
        width: 60%;
        // padding: 1.6rem 0;
        min-height: 0;
        padding: 3vh;
        padding-left: 3rem;
        display: flex;
        align-items: center;
        &:hover{
            // animation: 1s ease-out 0s 1 slide-menu;
            color: white;
            // transform: translateX(250px);
            // transition: transform 0.25s ease-in-out;
        }
    }
}

@keyframes slide-menu {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(200px);
    }
}

.main-circle{
    display: none;
    @include mq('lg'){
        display: block;
        position: absolute;
        right: 0;
        top: -20vh;
        width: 10rem;
        height: 20rem;
        border-radius: 100%;
        background-color: $portfolio-primary;
        border-radius: 10rem 0 0 10rem;
    }
}

.small-circle{
    display: none;
    @include mq('lg'){
        display: block;
        position: absolute;
        right: 0;
        top: -27vh;
        width: 13.75rem;
        height: 27.5rem;
        border-radius: 100%;
        border: 2px solid $portfolio-primary;
        border-radius: 13.75em 0 0 13.75rem;
        border-right: none;
    }
}

@for $i from 1 through 5 {
    .link{
        &:nth-child(#{$i}) {
            animation: ((1s + 0.2 * ($i - 1))) ease-out 0s 1 slide-sec;
            // animation-delay: $i * (1s / 18);
        }   
    }

}

.reviews-link{
    color: $primary;
    &:hover{
        color: lighten($primary, 10%);
    }
}

@keyframes slide {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-sec {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-pos {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 0.8;
        transform: translateX(0);
    }
}

#projects{
    width: 100%;
    color: $portfolio-primary;
    @include mq('lg'){
        display: grid;
        grid-template-areas: 
            "section-title section-title"
            "project-2 project-3"
            "project-4 project-5"
            ;
    }
}

#skills{
    color: $portfolio-primary;
}

#about{
    margin-top: 3rem;
    color: $portfolio-primary;
}

.section-title{
    font-size: 4rem;
    text-align: center;
    margin: 1rem auto;
    width: auto;
    position: relative;
    @include mq('lg'){
        grid-area: section-title;
    }
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  .about-container{
    background-color: $portfolio-primary;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 90%;
    @include mq('lg'){
        width: 75%;
    }
  }

  .about-header{
    text-align: center;
    padding: 1rem;
    font-size: 2rem;
    @include mq('lg'){
        padding: 1.5rem;
        font-size: 2.5rem;
    }
  }


  .about-text, .about-text-desc{
    margin: 1rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    @include mq('lg'){
        padding: 0 0.5rem;
        font-size: 1.75rem;
        line-height: 2rem;
    }
    // line-height: 1.5px;
  }

  .about-text-desc{
    margin-top: 0;
  }

  #contact{
    margin-top: 3rem;
    height: 110vh;
    color: $portfolio-primary;
    display: flex;
    flex-direction: column;
    @include mq('lg'){
        margin-top: 6rem;
        height: 100vh;
        justify-content: space-between
    }
  }

  .footer{
    margin-top: auto;
    width: 100%;
    background-color: $portfolio-primary;
    color: white;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mq('lg'){
        margin-top: 0;
        // align-self: flex-end;
    }
  }

  .mobile-links{
    padding: 1rem;
  }
  
 .mobile-icon{
    margin: auto 1rem;
    padding: 1rem;
 }

  .copyright{
    justify-self: center;
    text-align: center;
    padding: 1rem;
  }