@import './_variables.scss';
@import './_mixins.scss';

.skills-container{
    width: 90%;
    margin: auto;
    color: white;
    @include mq('lg'){
        display: flex;
        margin: 2.5rem auto;
        justify-content: space-between;
    }
    @include mq('xl'){
        justify-content: space-evenly;
    }
}

.skill-section{
    text-align: center;
    font-size: 2.5rem;
    padding: 1rem 0;
    border-radius: 10px;
    cursor: pointer;
    @include mq('lg'){
        padding: 1.5rem 3rem;
    }
    @include mq('xl'){
        padding: 2rem 6rem;
    }
}

.frontend{
    background-color: darken($portfolio-primary, 10%);
}
.tools{
    background-color: $portfolio-primary
}
.backend{
    background-color: lighten($portfolio-primary, 10%);
}

.skills-list{
    background-color: white;
    color: $portfolio-primary;
    font-size: 2rem;
    margin: 1rem auto;
    margin-bottom: 2rem;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 1rem;
    justify-items: center;
    transform-origin: top;
    transform: scaleY(1);
    transition: all 0.5s ease;
    max-height: 1200px;

    @include mq('lg'){
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
    @include mq('xl'){
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
    }
}

.closed {
    + .skills-list{
        transform: scaleY(0);
        max-height: 0;
        margin: 0;
    }
}

.dropdown-section.is-visible{
    @include mq('lg'){
        .closed{
            + .skills-list{
                transform-origin: top;
                transform: scaleY(1);
                margin: 1rem auto;
                margin-bottom: 2rem;
                transition: all 2s ease;
                max-height: 1200px;
            }
        }
    }
}

.skill-item{
    margin: 0.5rem;
    position: relative;
    width: 60px;
    height: 60px;
    &:hover{
        .skill-item-text{
            font-size: 1.3rem;
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            // left: 0;
            // right: 0;
            // right: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            // margin: 0 auto;
            // text-align: center;
            // top: 15px;
        }
    }
}

.skill-item-text{
    display: none;
}

.js{
    position: relative;
    z-index: 3;
    // &::before{
    //     content: '';
    //     background-color: black;
    //     width: 50px;
    //     height: 50px;
    //     display: block;
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    //     z-index: 1;
    // }
}

@keyframes growDown {
    0% {
      transform: scaleY(0)
    }
    100% {
      transform: scaleY(1)
    }
  }