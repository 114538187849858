@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin grid {
    display: grid;
    height: 100vh;
    height: 100svh;
}

@mixin header {
    background-color: $primary;

    h2{
        font-family: 'Source Code Pro';
    }
}

@mixin large-search($width) {
    width: $width;
    display: flex;
    .search{
        width: 200px;
        border-radius: 50px;
        justify-content: flex-start;
        svg{
            margin-left: 1rem;
        }
    }
    #search-input{
        display: block;
        width: calc(#{$width} - 1rem - 41px);
        margin-right: 1rem;
    }
    @content;
}

@mixin small-search{
    &:hover{
        @include large-search($width: 200px);
    }
    &:hover ~ .search-hide{
        display: none;
    }
}

@mixin mq($break) {
    $value: map-get($breakpoints, $break);
    $sm: map-get($breakpoints, 'sm');

    @if $value and $value > $sm {
        @media (min-width: $value) {
            @content;
        }
    }
    @else if $break == 'sm/med' {
        @media (min-width: map-get($breakpoints, 'sm') ) and (max-width: map-get($breakpoints, 'med') ) {
            @content;
        }
    }
    @else if $break == 'sm' {
        @media (max-width: $value) {
            @content;
        }
    }
}

@mixin animation-mixin($name, $from, $to) {
    @keyframes #{$name} {
      0% {transform: translate3d($from, 0, 0); opacity: 0;}
      100% {transform: translate3d($to, 0, 0); opacity: 1;}
    }
  }


.hide{
    visibility: hidden;
}