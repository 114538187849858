@import './_variables.scss';
@import './_mixins.scss';


.review-title-container h1{
    width: 100%;
    color: black;
    text-align: center;
}
.review-details-container{
    color: black;
    width: 85%;
    margin: auto;
    .review-details-title, .review-details-artist, .review-details-reviewed-at{
        text-align: center;
    }
    @include mq('lg'){
        // 7/2
        // 5/2
        display: grid;
        // grid-template-areas: 
        //     "title title title title title"
        //     "artist artist artist artist artist"
        //     "date date date date date"
        //     "image image excerpt excerpt excerpt"
        //     "share share text text text"
        //     "share share text text text";
        grid-template-areas: 
            "title title"
            "artist artist" 
            "date date" 
            "image excerpt"
            "image text"
            "image text"
            "share text"
            "share text"
    }
    // grid-template-columns: repeat(3, auto);
    // grid-template-rows: repeat(3, auto);
    // grid-auto-flow: column;
    // grid-auto-rows: 200px;
}

.review-details-artist{
    margin: 0.5rem;
    @include mq('med'){
        font-size: 2rem;
    }
    @include mq('lg'){
        grid-area: artist;
        margin: 0;
    }
}

.review-details-title{
    margin: 1rem auto;
    margin-bottom: 0;
    // width: 100vw;
    @include mq('med'){
        font-size: 2.5rem;
    }
    @include mq('lg'){
        grid-area: title;
        margin-bottom: 0.5rem;
    }
}

.review-details-rating{
    @include center;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    font-size: 3.5rem;
    font-weight: bold;
    color: white;
    margin: 1.25rem auto;
}

@each $rating, $color in $ratings {
    .#{$rating}{
        border: 1px solid $color;
        background-color: lighten($color, 5%);
    }
}

.review-details-rating-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @media screen and (min-width: 500px){
        .review-details-image{
            width: 250px;
            height: 250px;
        }
    }
    @include mq('med'){
        .review-details-image{
            width: 300px;
            height: 300px;
            &.vertical{
                height: 420px;
            }
        }
        .review-details-rating{
            width: 150px;
            height: 150px;
            font-size: 4.25rem;
        }
    }
    @include mq('lg'){
        grid-row: span 2;
    }
    // display: flex;
    // @include center;
    // justify-content: space-between;
}

.review-details-image{
    width: 200px;
    height: 200px;
    margin: auto;
    &.vertical{
        height: 310px;
    }
    @include mq('lg'){
        grid-area: image;
        margin: 0;
    }
}

.review-details-reviewed-at{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    @include mq('med'){
        font-size: 1.2rem;
    }
    @include mq('lg'){
        grid-area: date;
        font-size: 1.3rem;
    }
}

.review-details-excerpt{
    border-bottom: 1px solid black;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    text-align: center;
    @include mq('med'){
        font-size: 1.4rem;
    }
    @include mq('lg'){
        grid-area: excerpt;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

.content-paragraph{
    display: block;
    font-size: 1.1rem;
    line-height: 1.4rem;
    @include mq('med'){
        font-size: 1.2rem;
    }
    @include mq('lg'){
        line-height: 1.6rem;
        grid-area: text;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

