@import './variables.scss';
@import './mixins.scss';

.loading-container{
    @include center;
    height: calc(100vh - 4rem);
    height: calc(100svh - 4rem);
    max-height: -webkit-fill-available
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: $primary;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    @include mq('lg'){
        width: 60px;
        height: 60px;        
    }
}

    
    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
