@import './_mixins.scss';

.share-btn{
    margin-bottom: 1rem;
    // height: 70px;
    border-radius: 50px;
    width: 100%;
    margin: 1rem auto;
    @include mq('lg'){
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        @include center;
    }
}

.share-container{
    display: flex;
    justify-content: space-between;
    width: fit-content;

    margin: auto;
    @include mq('lg'){
        max-width: 100%;
        width: 80%;
    }
}

.social-icon{
    @include center;
    width: 45px;
    height: 45px;
    transition: all 0.3s;
    cursor: pointer;
    margin: auto 1rem;
    &:hover{
        transform: translateY(-3px);
    }
    @include mq('med'){
        width: 55px;
        height: 55px;
        margin: auto 1.5rem;
    }
    @include mq('lg'){
        margin: 0;
        width: 50px;
        height: 50px;
    }
}

.email{
    width: 35px;
    height: 35px;
}